$hyoo_draw_tools $mol_switch
	value?val \
	keys /
		\pencil
		\filler
		\eraser
	Icon_pencil $mol_icon_lead_pencil
	Icon_eraser $mol_icon_eraser
	Icon_filler $mol_icon_format_color_fill
	hint_pencil @ \Draw curve
	hint_filler @ \Fill region
	hint_eraser @ \Erase color
