$hyoo_draw_pane $mol_plot_pane
	color \blue
	tool \pencil
	grid false
	map false
	yard $hyoo_sync_yard<any>
	reset?event null
	auto /
		<= peer_update null
		<= figure_current null
	shift?val $mol_vector_2d<number> /
		0
		0
	scale?val $mol_vector_2d<number> /
		<= zoom
		<= zoom
	Map $mol_plot_map_tiles
		level_pyramid -3
		tile_size_real <= tile_size 256
		uri_template <= tiles_uri \https://basemaps.cartocdn.com/rastertiles/voyager/{level}/{x}/{y}.png
	Line!id $mol_plot_line
		color <= line_color!id \
		series_x <= line_x!id /number
		series_y <= line_y!id /number
	Fill!id $mol_plot_line
		color <= line_color!id \
		series_x <= line_x!id /number
		series_y <= line_y!id /number
	Peer!id $mol_plot_line
		color <= peer_color!id \
		series_x <= peer_x!id /number
		series_y <= peer_y!id /number
	Ruler_vert $mol_plot_ruler_vert
		front /
		title \
	Ruler_hor $mol_plot_ruler_hor
		front /
		title \
	-
	snapshot \
	snapshot_current \
